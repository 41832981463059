//back to this
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';

const MyBookingScreen = () => {
    const [bookingId, setBookingId] = useState('');
    const [transactionId, setTransactionId] = useState('');
    const [bookingDetails, setBookingDetails] = useState(null);
    const [branding, setBranding] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [modification, setModification] = useState({
        guestName: '',
        guestEmail: '',
        guestNationality: '',
        specialRequest: '',
    });

    useEffect(() => {
        const fetchBranding = async () => {
            try {
                const brandingResponse = await axios.get('/api/branding/getallbranding');
                setBranding(brandingResponse.data);
            } catch (error) {
                console.error('Error fetching branding settings:', error);
            }
        };
        fetchBranding();
    }, []);

    const validateBooking = async () => {
        setIsLoading(true);
        try {
            const response = await axios.post('/api/bookings/validatebooking', { bookingId, transactionId });
            if (response.data.valid) {
                const bookingResponse = await axios.get(`/api/bookings/getBookingById?orderId=${bookingId}`);
                setBookingDetails(bookingResponse.data.booking);
                setModification({
                    guestName: bookingResponse.data.booking.guestName,
                    guestEmail: bookingResponse.data.booking.guestEmail,
                    guestNationality: bookingResponse.data.booking.guestNationality,
                    specialRequest: bookingResponse.data.booking.specialRequest,
                });
                Swal.fire('Success!', 'Booking validated successfully!', 'success');
            } else {
                Swal.fire('Error!', 'Invalid booking ID or transaction ID.', 'error');
            }
        } catch (err) {
            Swal.fire('Error!', 'An error occurred while validating the booking.', 'error');
        }
        setIsLoading(false);
    };

    const modifyBooking = async () => {
        try {
            await axios.post('/api/bookings/modify', { bookingId, ...modification });
            Swal.fire('Success!', 'Booking modified successfully!', 'success');
        } catch (err) {
            Swal.fire('Error!', 'Failed to modify the booking.', 'error');
        }
    };

    const cancelBooking = async () => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You will not be able to recover this booking!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, cancel it!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await axios.post('/api/bookings/cancelbooking', { bookingid: bookingId });
                    Swal.fire('Canceled!', 'Your booking has been canceled.', 'success');
                    setBookingDetails(null); // Reset booking details
                } catch (err) {
                    Swal.fire('Error!', 'Failed to cancel the booking.', 'error');
                }
            }
        });
    };

    if (!branding) {
        return <p>Loading branding...</p>;
    }

    return (
        <div
            className="container mt-4"
            style={{
                backgroundImage: `#fff`,
                backgroundSize: 'cover',
                padding: '20px',
                borderRadius: '8px',
            }}
        >
            <h1 style={{ color: branding.headingColor }}>Manage Your Booking</h1>
            <div className="form-group mt-3">
                <label>Booking ID</label>
                <input
                    type="text"
                    className="form-control"
                    value={bookingId}
                    onChange={(e) => setBookingId(e.target.value)}
                />
            </div>
            <div className="form-group mt-3">
                <label>Transaction ID</label>
                <input
                    type="text"
                    className="form-control"
                    value={transactionId}
                    onChange={(e) => setTransactionId(e.target.value)}
                />
            </div>
            <button className="btn btn-primary mt-3" onClick={validateBooking} disabled={isLoading}>
                {isLoading ? 'Validating...' : 'Validate Booking'}
            </button>

            {bookingDetails && (
                <div className="mt-4">
                    <h3 style={{ color: branding.headingColor }}>Modify Booking Details</h3>
                    <div className="form-group mt-3">
                        <label>Guest Name</label>
                        <input
                            type="text"
                            className="form-control"
                            value={modification.guestName}
                            onChange={(e) => setModification({ ...modification, guestName: e.target.value })}
                        />
                    </div>
                    <div className="form-group mt-3">
                        <label>Guest Email</label>
                        <input
                            type="email"
                            className="form-control"
                            value={modification.guestEmail}
                            onChange={(e) => setModification({ ...modification, guestEmail: e.target.value })}
                        />
                    </div>
                    <div className="form-group mt-3">
                        <label>Guest Nationality</label>
                        <input
                            type="text"
                            className="form-control"
                            value={modification.guestNationality}
                            onChange={(e) => setModification({ ...modification, guestNationality: e.target.value })}
                        />
                    </div>
                    <div className="form-group mt-3">
                        <label>Special Request</label>
                        <textarea
                            className="form-control"
                            value={modification.specialRequest}
                            onChange={(e) => setModification({ ...modification, specialRequest: e.target.value })}
                        />
                    </div>

                    <h3 className='mt-3' style={{ color: branding.headingColor }}>Booking Information</h3>
                    <table className="table table-bordered">
                        <tbody>
                            <tr>
                                <th>Check-in Date</th>
                                <td>{bookingDetails.fromdate}</td>
                            </tr>
                            <tr>
                                <th>Check-out Date</th>
                                <td>{bookingDetails.todate}</td>
                            </tr>
                            <tr>
                                <th>Total Amount</th>
                                <td>{bookingDetails.totalamount.toLocaleString()} VND</td>
                            </tr>
                            <tr>
                                <th>Total Days</th>
                                <td>{bookingDetails.totaldays}</td>
                            </tr>
                            <tr>
                                <th>Transaction ID</th>
                                <td>{bookingDetails.transactionId}</td>
                            </tr>
                            <tr>
                                <th>Payment Method</th>
                                <td>{bookingDetails.paymentMethod}</td>
                            </tr>
                            <tr>
                                <th>Created At</th>
                                <td>{new Date(bookingDetails.createdAt).toLocaleString()}</td>
                            </tr>
                            <tr>
                                <th>Updated At</th>
                                <td>{new Date(bookingDetails.updatedAt).toLocaleString()}</td>
                            </tr>
                        </tbody>
                    </table>

                    {/* Room Details */}
                    <h3 className="mt-4" style={{ color: branding.headingColor }}>Room Details</h3>
                    {bookingDetails.rooms.map((room, index) => {
                        console.log(room); // Debugging log to check the `room` object
                        return (
                            <div
                                key={index}
                                className="mt-3"
                                style={{
                                    border: '1px solid #ddd',
                                    borderRadius: '8px',
                                    overflow: 'hidden',
                                    backgroundColor: '#f8f8f8',
                                }}
                            >
                                <div
                                    style={{
                                        backgroundColor: '#6c757d',
                                        color: '#fff',
                                        padding: '10px',
                                        fontSize: '16px',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    Room {index + 1}: {room.room}
                                </div>
                                <table className="table">
                                    <tbody>
                                        <tr>
                                            <th>Number of Rooms</th>
                                            <td>{room.numberOfRooms}</td>
                                        </tr>
                                        <tr>
                                            <th>Rate Plan</th>
                                            <td>{room.ratePlanName}</td>
                                        </tr>
                                        <tr>
                                            <th>Rate</th>
                                            <td>{room.ratePlanRate.toLocaleString()} VND</td>
                                        </tr>
                                        <tr>
                                            <th>Adults</th>
                                            <td>{room.adults}</td>
                                        </tr>
                                        <tr>
                                            <th>Children</th>
                                            <td>{room.children}</td>
                                        </tr>
                                        <tr>
                                            <th>Extra Bed</th>
                                            <td>
                                                {Object.values(room.extraBed || {}).some((value) => value === true)
                                                    ? `Yes`
                                                    : 'No'}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Cancellation Policy</th>
                                            <td>{room.cancellationPolicy || 'Flexible'}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        );
                    })}



                    {/* Add-On Services */}
                    <h3 className="mt-4" style={{ color: branding.headingColor }}>Add-On Services</h3>
                    {bookingDetails.addonServices.length > 0 ? (
                        <div
                            style={{
                                marginTop: '20px',
                                border: '1px solid #ddd',
                                borderRadius: '8px',
                                overflow: 'hidden',
                                backgroundColor: '#f8f8f8',
                            }}
                        >
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Service Name</th>
                                        <th>Description</th>
                                        <th>Price</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {bookingDetails.addonServices.map((service, index) => (
                                        <tr key={index}>
                                            <td>{service.serviceName}</td>
                                            <td>{service.serviceDescription || 'N/A'}</td>
                                            <td>{service.servicePrice.toLocaleString()} VND</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    ) : (
                        <p>No add-on services selected.</p>
                    )}

                    <button className="btn btn-success mt-3" onClick={modifyBooking}>
                        Modify Booking
                    </button>
                    <button className="btn btn-danger mt-3 ms-2" onClick={cancelBooking}>
                        Cancel Booking
                    </button>
                </div>
            )}
        </div>
    );
};

export default MyBookingScreen;
