
import React, { useState, useEffect, useContext } from "react";
import { Button, Modal } from 'react-bootstrap';
import Carousel from 'react-bootstrap/Carousel';  // Retaining Carousel
import moment from 'moment';
import { Tag, Divider, Rate, Form, Input, message } from 'antd';
import { BiBed } from 'react-icons/bi';
import { LikeOutlined } from '@ant-design/icons';
import './Room.css';
import { useTranslation } from 'react-i18next';
import { PlusOutlined, EnvironmentOutlined, RadiusSettingOutlined, UserOutlined, TeamOutlined, HomeOutlined, CheckCircleOutlined, CloseCircleOutlined, InfoCircleOutlined, TagOutlined } from '@ant-design/icons';
import axios from 'axios';

import { CurrencyContext } from '../components/CurrencyContext';


function Room({ room, fromdate, todate, ratePlans = [], handlePickRatePlan, handleRemoveRatePlan, handleRoomChange, bookingSidebar, getAvailableRooms, handlePickAddOnService, addonservices = [], promoDiscount, promoCode }) {
    const [show, setShow] = useState(false);  // Retaining 'show'
    const [showAddOnServices, setShowAddOnServices] = useState(false); // State to toggle Add-on Services visibility
    const [rates, setRates] = useState({});
    const [promotions, setPromotions] = useState([]);
    const [showServiceModal, setShowServiceModal] = useState(false); // State for Add-on Service modal visibility
    const [selectedService, setSelectedService] = useState(null); // State for selected service
    const [bookingId, setBookingId] = useState("");
    const [isBookingValid, setIsBookingValid] = useState(false);
    const [guestName, setGuestName] = useState("");
    const [avgRating, setAvgRating] = useState(0);
    const [userRating, setUserRating] = useState(0);
    const [userComment, setUserComment] = useState("");
    const [showRatingModal, setShowRatingModal] = useState(false);
    const [branding, setBranding] = useState({ roomNameColor: '#443d80' });
    const { t } = useTranslation();
    const { currency, convertAmount } = useContext(CurrencyContext);


    const formatCurrency = (amount) => {
        const convertedAmount = convertAmount(amount, currency);
        return `${new Intl.NumberFormat('vi-VN', { maximumFractionDigits: 0 }).format(convertedAmount)} ${currency}`;
    };

    useEffect(() => {
        const fetchPromotions = async () => {
            try {
                const response = await axios.get('/api/promotions/getallpromotions');
                setPromotions(response.data);
            } catch (error) {
                console.error("Error fetching promotions:", error);
            }
        };

        fetchPromotions();
    }, []);

    useEffect(() => {
        const fetchRating = async () => {
            try {
                if (room._id) {
                    const response = await axios.get(`/api/rooms/review/${room._id}`);
                    setAvgRating(response.data.avgRating);  // Set the average rating from the backend
                }
            } catch (error) {
                console.error("Error fetching room rating:", error);
            }
        };

        fetchRating();  // Fetch rating when room ID changes
    }, [room._id]);

    useEffect(() => {
        const calculateRates = () => {
            const calculatedRates = {};
            const today = moment();

            if (fromdate === todate) {
                setRates({}); // Clear rates if check-in and check-out dates are the same
                message.error("Check-in and check-out dates cannot be the same."); // Show alert message
                return;
            }

            if (Array.isArray(ratePlans)) {
                ratePlans.forEach(ratePlan => {
                    const pricing = ratePlan.rateplanpricing.find(p => p.roomId === room._id);
                    let totalRate = 0;
                    let startDate = moment(fromdate, 'DD-MM-YYYY');
                    const endDate = moment(todate, 'DD-MM-YYYY').subtract(1, 'days');
                    let hasValidRate = true;
                    let originalTotalRate = 0;

                    while (startDate.isSameOrBefore(endDate)) {
                        const dateStr = startDate.format('DD-MM-YYYY');
                        let dailyRate = 0;

                        if (ratePlan.ratePlanSetup === "Derived") {
                            // Always use rentperdayByDate for derived rate plans
                            dailyRate = room.rentperdayByDate?.[dateStr] || room.rentperday;
                        } else if (pricing && pricing.manualRate && pricing.manualRate[dateStr] !== undefined) {
                            // Use manual rate if available for the date
                            dailyRate = pricing.manualRate[dateStr];
                        } else {
                            // No valid rate found
                            hasValidRate = false;
                            break;
                        }

                        totalRate += dailyRate;
                        originalTotalRate += dailyRate;
                        startDate = startDate.add(1, 'days');
                    }

                    if (hasValidRate) {
                        let discountedRate = totalRate;
                        const appliedPromotions = [];  // Initialize array for applied promotions

                        promotions.forEach(promotion => {
                            const applicablePromotion = promotion.applicableRooms.includes(room._id) &&
                                promotion.applicableRatePlans.includes(ratePlan._id);

                            if (applicablePromotion) {
                                const checkInDate = moment(fromdate, 'DD-MM-YYYY');
                                const isWithinBookingPeriod = today.isBetween(
                                    moment(promotion.applicableBookDates.startDate),
                                    moment(promotion.applicableBookDates.endDate),
                                    null,
                                    '[]'
                                );

                                let discountApplied = false;

                                if (promotion.promotionType === 'Last Minute') {
                                    const lastMinuteDays = promotion.condition?.lastMinuteDays || 3;
                                    const daysBeforeCheckIn = checkInDate.startOf('day').diff(today.startOf('day'), 'days') + 1;

                                    if (daysBeforeCheckIn >= 0 && daysBeforeCheckIn <= lastMinuteDays) {
                                        discountedRate *= (1 - promotion.discountPercentage / 100);
                                        appliedPromotions.push({
                                            type: promotion.promotionType,
                                            percentage: promotion.discountPercentage,
                                        });
                                        discountApplied = true;
                                    }
                                }

                                if (promotion.promotionType === 'Early Bird' && !discountApplied) {
                                    const earlyBirdDays = promotion.condition?.earlyBirdDays || 30;
                                    const daysBeforeCheckIn = checkInDate.diff(today, 'days');

                                    if (daysBeforeCheckIn >= earlyBirdDays) {
                                        discountedRate *= (1 - promotion.discountPercentage / 100);
                                        appliedPromotions.push({
                                            type: promotion.promotionType,
                                            percentage: promotion.discountPercentage,
                                        });
                                    }
                                }

                                if (promotion.promotionType === 'Basic Deal' && isWithinBookingPeriod && !discountApplied) {
                                    const { startDate: stayStartDate, endDate: stayEndDate } = promotion.applicableStayDates;
                                    const applicableStayStartDate = moment(stayStartDate);
                                    const applicableStayEndDate = moment(stayEndDate);

                                    if (checkInDate.isBetween(applicableStayStartDate, applicableStayEndDate, null, '[]')) {
                                        discountedRate *= (1 - promotion.discountPercentage / 100);
                                        appliedPromotions.push({
                                            type: promotion.promotionType,
                                            percentage: promotion.discountPercentage,
                                        });
                                    }
                                }
                            }
                        });

                        // Apply promo code discount if available
                        if (promoDiscount) {
                            discountedRate *= (1 - promoDiscount / 100);
                            appliedPromotions.push({
                                type: 'Promo Code',
                                name: promoCode,
                                percentage: promoDiscount,
                            });
                        }

                        calculatedRates[ratePlan.rateplanname] = {
                            totalRate: discountedRate,
                            originalTotalRate: originalTotalRate,
                            ratePlanId: ratePlan._id,
                            ratePlanName: ratePlan.rateplanname,
                            selectedRooms: 0,
                            roomId: room._id,
                            cancellationPolicy: ratePlan.cancellationPolicy,
                            mealPlan: ratePlan.mealPlan,
                            appliedPromotions: appliedPromotions,  // Save the promotions array
                        };
                    }
                });

                setRates(calculatedRates);
            }
        };

        if (fromdate && todate) {
            calculateRates();
        }
    }, [fromdate, todate, ratePlans, room, promotions, promoDiscount, promoCode]);

    useEffect(() => {
        const fetchBranding = async () => {
            try {
                const response = await axios.get('/api/branding/getallbranding');
                setBranding(response.data || { roomNameColor: '#443d80' });
            } catch (error) {
                console.error("Error fetching branding settings:", error);
            }
        };

        fetchBranding();
    }, []);


    const handleClose = () => setShow(false);  // Retain handleClose function
    const handleShow = () => setShow(true);    // Use show for opening modal

    // Toggle add-on services dropdown
    const toggleAddOnServices = () => setShowAddOnServices(!showAddOnServices);

    // Show service modal
    const handleShowServiceModal = (service) => {
        setSelectedService(service);
        setShowServiceModal(true);
    };

    // Close service modal
    const handleCloseServiceModal = () => {
        setSelectedService(null);
        setShowServiceModal(false);
    };

    const handleRatingSubmit = async () => {
        try {
            await axios.post(`/api/rooms/rating/${room._id}`, {
                bookingId,
                guestName: guestName,
                rating: userRating,
                comment: userComment // Send the review comment
            });
            setShowRatingModal(false);
            message.success("Rating and review submitted successfully."); // Close the modal after submission
            // Fetch the updated average rating after submission
            const response = await axios.get(`/api/rooms/review/${room._id}`);
            setAvgRating(response.data.avgRating);
        } catch (error) {
            console.error("Error submitting rating and review:", error);
        }
    };

    const handleValidateBooking = async () => {
        try {
            const response = await axios.post(`/api/bookings/validate`, { bookingId });
            if (response.data.valid) {
                setIsBookingValid(true);
                message.success("Booking ID validated. You can now rate the room.");
            } else {
                message.error("Invalid booking ID. Please try again.");
            }
        } catch (error) {
            console.error("Error validating booking:", error);
            message.error("An error occurred. Please try again.");
        }
    };

    return (
        <>
            <div className="glass-effect">
                <div className="row bs">
                    <div className="col-md-6 col-12 mb-3">
                        <img src={room.imageurls[0]} className='img-fluid smallimg' alt={room.name} />
                        <p className="d-none d-md-block"><i>"{room.description}"</i></p>
                        <div className="room-header">
                            <div className="rating-section">
                                <strong>{t('room.average_rating')}</strong>  <Rate
                                    allowHalf disabled value={avgRating}
                                    style={{
                                        color: branding.starRatingColor,
                                        textDecoration: 'underline',
                                        cursor: 'pointer',
                                        fontSize: '16px',
                                    }}
                                />
                                <button
                                    type="button"
                                    onClick={() => setShowRatingModal(true)}  // Open the rating modal
                                    style={{
                                        background: 'none',
                                        border: 'none',
                                        color: branding.starRatingColor,
                                        textDecoration: 'underline',
                                        cursor: 'pointer',
                                        fontSize: '16px',
                                    }}
                                >
                                    <LikeOutlined style={{ color: '#443d80', fontSize: '16px' }} />
                                    {t('room.rate_this_room')}
                                </button>
                            </div>
                            {/* Rating & Review Modal */}
                            <Modal
                                title="Rate & Review"
                                show={showRatingModal}  // Changed 'show' to 'visible'
                                onOk={handleRatingSubmit}
                                onCancel={() => setShowRatingModal(false)}  // Use 'onCancel' for closing the modal
                                footer={null}  // No footer as we have custom buttons
                            >
                                <Form layout="vertical">
                                    {/* Booking ID */}
                                    <Form.Item
                                        label={<strong>Booking ID</strong>}  // Bold label
                                        style={{ margin: '24px' }}  // Adjust line spacing with smaller margin
                                    >
                                        <Input
                                            value={bookingId}
                                            onChange={(e) => setBookingId(e.target.value)}
                                            placeholder="Enter your booking ID to review"
                                        />
                                    </Form.Item>

                                    {/* Validate Booking Button */}
                                    <Button
                                        type="primary"
                                        onClick={handleValidateBooking}
                                        block  // Make the button take full width
                                        style={{ marginBottom: '24px', marginLeft: '24px' }}  // Smaller spacing between items
                                    >
                                        {t('room.validate')}
                                    </Button>
                                    {/* Close Button */}
                                    <Button
                                        type="default"
                                        onClick={() => setShowRatingModal(false)}  // Close modal
                                        style={{ flex: 1, marginBottom: '24px', marginLeft: '24px' }}  // Make buttons inline
                                    >
                                        {t('room.close')}
                                    </Button>

                                    {isBookingValid && (
                                        <>
                                            {/* Guest Name */}
                                            <Form.Item
                                                label={<strong>{t('room.guest_name')}</strong>}  // Bold label
                                                style={{ margin: '24px' }}  // Adjust line spacing
                                            >
                                                <Input
                                                    placeholder="Enter your name"
                                                    value={guestName}
                                                    onChange={(e) => setGuestName(e.target.value)}
                                                />
                                            </Form.Item>

                                            {/* Rating */}
                                            <Form.Item
                                                label={<strong>{t('room.rate_the_room')}</strong>}  // Bold label
                                                style={{ margin: '24px' }}  // Adjust line spacing
                                            >
                                                <Rate
                                                    value={userRating}
                                                    onChange={setUserRating}
                                                    style={{
                                                        background: 'none',
                                                        border: 'none',
                                                        color: branding.starRatingColor,
                                                        cursor: 'pointer',
                                                        fontSize: '20px',
                                                    }}

                                                />
                                            </Form.Item>

                                            {/* Review */}
                                            <Form.Item
                                                label={<strong>{t('room.your_review')}</strong>}  // Bold label
                                                style={{ margin: '24px' }}  // Adjust line spacing
                                            >
                                                <Input.TextArea
                                                    rows={3}
                                                    placeholder="Write your review here..."
                                                    value={userComment}
                                                    onChange={(e) => setUserComment(e.target.value)}
                                                />
                                            </Form.Item>

                                            {/* Submit Rating Button */}
                                            <Button
                                                type="primary"
                                                onClick={handleRatingSubmit}
                                                style={{ margin: '24px' }}
                                                disabled={!userRating || !userComment}
                                                block  // Make the button take full width
                                            >
                                                {t('room.submit_rating')}
                                            </Button>
                                        </>
                                    )}
                                </Form>
                            </Modal>


                        </div>
                    </div>
                    <div className="room-info col-md-6 col-12">
                        <h1 style={{ color: branding.roomNameColor }}><strong>{room.name}</strong></h1>
                        <p>
                            <RadiusSettingOutlined />
                            <strong>{t('room.room_size')}:</strong>
                            <span style={{ color: branding.paragraphColor }}> {room.roomsize} sq meter</span>
                        </p>

                        <p>
                            <TagOutlined /> <strong>{t('room.room_type')}:</strong>
                            <span style={{ color: branding.paragraphColor }}> {room.type}</span>
                        </p>

                        <p>
                            <EnvironmentOutlined /> <strong>{t('room.view')}:</strong>
                            <span style={{ color: branding.paragraphColor }}> {room.roomview}</span>
                        </p>

                        <p>
                            <BiBed /><strong>{t('room.bedding')}:</strong>
                            <span style={{ color: branding.paragraphColor }}> {room.bedding}</span>
                        </p>

                        <div className="d-flex flex-wrap ">
                            <p className="me-3">
                                <UserOutlined /><strong>{t('room.max_adults')}:</strong>
                                <span style={{ color: branding.paragraphColor }}> {room.maxadult}</span>
                            </p>
                            <p className="me-3">
                                <TeamOutlined /><strong>{t('room.max_children')}:</strong>
                                <span style={{ color: branding.paragraphColor }}> {room.maxchild}</span>
                            </p>
                            <p>
                                <CheckCircleOutlined /> <strong>{t('room.extra_bed')}:</strong>
                                <span style={{ color: branding.paragraphColor }}> {room.extrabed ? "Yes" : "No"}</span>
                            </p>
                        </div>

                        <p>
                            <HomeOutlined /> <strong>{t('room.facilities')}:</strong>
                            <span style={{ color: branding.paragraphColor }}>
                                {room.facilities.slice(0, 6).join(', ')}
                                {room.facilities.length > 6 && '...'}
                            </span>
                        </p>
                        <Button
                            className="apply-button mb-3"
                            style={{
                                backgroundColor: branding.buttonColor, // Apply buttonColor from branding
                                borderColor: branding.buttonColor,     // Match border color for consistency
                            }}
                            onClick={handleShow}
                        >
                            {t('room.details')}
                        </Button>
                    </div>
                    <hr />
                    <div className="rate-info col-md-12">
                        {Object.keys(rates).map((rateplanname, index) => (
                            <React.Fragment key={index}>
                                <div className="promo-tag-row mb-2">
                                    {rates[rateplanname].appliedPromotions?.map((promotion, idx) => (
                                        <Tag
                                            color={promotion.type === 'Promo Code' ? "blue" : "green"}
                                            className="promo-tag"
                                            key={idx}
                                        >
                                            {promotion.type === "Promo Code" ? `Code:` : `  ${promotion.type}`} {promotion.percentage}% Off

                                        </Tag>
                                    ))}
                                </div>
                                <div className="d-flex align-items-center" style={{ marginBottom: '8px' }}>
                                    <p style={{ fontSize: '20px', fontFamily: 'Bebas Neue', marginRight: '10px', color: branding.headingColor }}>
                                        <strong>{rateplanname}:</strong>
                                    </p>
                                    <div className="ms-auto d-flex align-items-center">
                                        <div className="rate-pricing mb-3">
                                            {rates[rateplanname].appliedPromotions?.length > 0 && (
                                                <span className="original-rate" style={{ fontSize: '16px', fontFamily: 'Bebas Neue', textDecoration: 'line-through', marginBottom: '15px', marginRight: '8px', color: branding.priceTagColor }}>
                                                    {formatCurrency(rates[rateplanname].originalTotalRate)}
                                                </span>
                                            )}

                                            <span className="total-rate" style={{ fontSize: '25px', fontFamily: 'Bebas Neue', color: branding.priceTagColor, marginRight: '8px', marginTop: '10px' }}>
                                                <strong>{formatCurrency(rates[rateplanname].totalRate)}</strong>
                                            </span>
                                        </div>
                                        <Button
                                            variant="success"
                                            className="pick-button mb-4"
                                            onClick={() => {
                                                handlePickRatePlan(rateplanname, rates[rateplanname]);
                                                if (!showAddOnServices) setShowAddOnServices(true); // Ensure Add-On Services are open
                                            }}
                                        >
                                            {t('room.pick')}
                                        </Button>
                                    </div>
                                </div>
                                <p className="meal-cancel" style={{ fontSize: '12px', fontStyle: 'italic', color: '#555', marginTop: '-30px', marginBottom: '8px' }}>
                                    <span>
                                        <InfoCircleOutlined style={{ marginRight: '5px' }} />
                                        <strong>{t('room.meal_plan')}:</strong> {rates[rateplanname].mealPlan}
                                    </span>
                                    <br />
                                    <span>
                                        <InfoCircleOutlined style={{ marginRight: '5px' }} />
                                        <strong>{t('room.cancellation_policy')}:  </strong>
                                        {rates[rateplanname].cancellationPolicy.chargeType === 'Non-refundable' ? (
                                            <>{rates[rateplanname].cancellationPolicy.chargeType} - {rates[rateplanname].cancellationPolicy.chargePercentage}% charge.</>
                                        ) : (
                                            <>
                                                {rates[rateplanname].cancellationPolicy.chargeType} - {rates[rateplanname].cancellationPolicy.chargePercentage}% charge if canceled {rates[rateplanname].cancellationPolicy.daysBeforeCheckin} days before check-in.
                                            </>
                                        )}
                                    </span>
                                </p>
                                <Divider style={{ margin: '8px 0' }} />
                            </React.Fragment>
                        ))}
                    </div>
                </div>

                {/* Add-on Services Section */}
                <div className="col-md-12 mt-2">
                    <h5 style={{ color: '#443d80' }}>
                        <Button variant="link" onClick={toggleAddOnServices} style={{ padding: '0', fontSize: '16px' }}>
                            <strong>{t('room.add_on_services')}</strong> {showAddOnServices ? "▲" : "▼"}
                        </Button>
                    </h5>
                    {showAddOnServices && addonservices && addonservices.length > 0 ? (
                        addonservices.map((service, index) => (
                            <React.Fragment key={index}>
                                <div className="d-flex align-items-center" style={{ marginBottom: '5px', color: 'white' }}>
                                    <div className="col-md-2 ">
                                        {/* Service Photo */}
                                        {service.photo ? (
                                            <img
                                                src={service.photo}
                                                alt={service.serviceName}
                                                style={{
                                                    width: '100px',   // Set width
                                                    height: '100px',  // Set height to make it square
                                                    objectFit: 'cover',  // Ensures the image fills the square without distortion
                                                    borderRadius: '5px'
                                                }}
                                            />
                                        ) : (
                                            <p>No photo available</p>
                                        )}
                                    </div>
                                    <div className="col-md-6">
                                        <p className="add-on" style={{ fontSize: '18px', fontFamily: 'Comfortaa', marginLeft: '10px', textJustify: 'inter-word' }}>
                                            <strong>{service.serviceName}</strong>
                                            <p className="add-on-info" style={{ fontSize: '12px', fontStyle: 'italic', color: '#fff', marginBottom: '8px' }}>
                                                <span><InfoCircleOutlined className="add-on-info" style={{ color: '#fff', fontSize: '15px', marginRight: '5px' }} />{service.serviceDescription}</span> <br />
                                                <span
                                                    className="add-on-info"
                                                    onClick={() => handleShowServiceModal(service)}
                                                    style={{
                                                        fontSize: '15px',
                                                        fontStyle: 'italic',
                                                        marginTop: '6px',
                                                        textDecoration: 'underline',
                                                        color: '#fff',
                                                        cursor: 'pointer',
                                                    }}
                                                    onMouseEnter={(e) => (e.target.style.color = '#E9A6FF')} // Hover effect
                                                    onMouseLeave={(e) => (e.target.style.color = '#fff')} // Revert to original color
                                                ><InfoCircleOutlined className="add-on-info" style={{ color: '#fff', fontSize: '16px', marginRight: '5px' }} />
                                                    {t('room.more_info')}
                                                </span>
                                            </p>
                                        </p>
                                    </div>
                                    <div className="ms-auto d-flex align-items-center">
                                        <p className="add-on-price" style={{ fontSize: '20px', fontFamily: 'UTM Bebas', color: '#fff', marginRight: '8px' }}>
                                            <strong>{formatCurrency(service.servicePrice)} VND</strong>
                                            <p style={{ fontSize: '15px', fontFamily: 'UTM Bebas', color: '#fff', marginRight: '8px' }}>({service.chargeType})</p>
                                        </p>
                                        <Button
                                            variant="success"
                                            className="pick-button mb-4"
                                            onClick={() => handlePickAddOnService(service)}
                                            style={{
                                                backgroundColor: branding.buttonColor, // Apply buttonColor from branding
                                                borderColor: branding.buttonColor,     // Match border color for consistency
                                            }}
                                        >
                                            <PlusOutlined />
                                        </Button>
                                    </div>
                                </div>
                                <Divider style={{ margin: '8px 0' }} />
                            </React.Fragment>
                        ))
                    ) : (
                        <i style={{ fontSize: '13px', fontStyle: 'italic', color: '#fff', marginBottom: '15px' }}>View our Add-on Services</i>
                    )}
                </div>

            </div>

            {/* Add-On Service Modal */}
            <Modal show={showServiceModal} onHide={handleCloseServiceModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{selectedService?.serviceName}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedService && (
                        <>
                            {selectedService.photo && (
                                <img
                                    src={selectedService.photo}
                                    alt={selectedService.serviceName}
                                    style={{
                                        width: '100%',
                                        height: '200px',
                                        objectFit: 'cover',
                                        borderRadius: '5px',
                                        marginBottom: '15px'
                                    }}
                                />
                            )}
                            <p><strong>{t('room.service_type')}:</strong> {selectedService.kindOfService}</p>
                            <p><strong>{t('room.charge_type')}:</strong> {selectedService.chargeType}</p>
                            <p><strong>{t('room.price')}:</strong> {selectedService.servicePrice} VND</p>
                            <p><strong>{t('room.description')}:</strong> {selectedService.serviceDescription}</p>
                            <p><strong>{t('room.date_applied')}:</strong> {moment(selectedService.dateApplied.startDate).format('DD-MM-YYYY')} - {moment(selectedService.dateApplied.endDate).format('DD-MM-YYYY')}</p>
                        </>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseServiceModal}>
                        {t('room.close')}
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Room Details Modal */}
            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title style={{ color: '#443d80' }}><strong>{room.name}</strong></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Carousel>
                        {room.imageurls.map((url, index) => (
                            <Carousel.Item key={index}>
                                <img className="d-block w-100 bigimg" src={url} alt={`Slide ${index + 1}`} />
                            </Carousel.Item>
                        ))}
                    </Carousel>
                    <h5 className="mt-4" style={{ color: '#433d80' }}><InfoCircleOutlined /> <strong>{t('room.description')}</strong></h5>
                    <p>{room.description}</p>

                    {/* Room Information Section */}
                    <div className="room-details mt-4">
                        <h5 style={{ color: '#433d80' }}><HomeOutlined /> <strong>Room Information</strong></h5>
                        <div className="row">
                            <div className="col-md-6">
                                <p><EnvironmentOutlined /> <strong>{t('room.view')}:</strong> {room.roomview}</p>
                                <p><BiBed /><strong>{t('room.bedding')}:</strong> {room.bedding}</p>
                                <p><RadiusSettingOutlined /><strong>{t('room.room_size')}:</strong> {room.roomsize} sq meters</p>
                                <p><TagOutlined /> <strong>{t('room.room_type')}:</strong> {room.type}</p>
                            </div>
                            <div className="col-md-6">
                                <p><UserOutlined /> <strong>{t('room.max_adults')}:</strong> {room.maxadult}</p>
                                <p><TeamOutlined /> <strong>{t('room.max_children')}:</strong> {room.maxchild}</p>
                                <p><CheckCircleOutlined /> <strong>{t('room.extra_bed')}:</strong> {room.extrabed ? "Yes" : "No"}</p>
                                <p>{room.smokingpolicy ? <CheckCircleOutlined /> : <CloseCircleOutlined />} <strong>{t('room.smoking_policy')}:</strong> {room.smokingpolicy ? "Yes" : "No"}</p>
                            </div>
                        </div>

                        <h5 className="mt-4" style={{ color: '#433d80' }}><TagOutlined /> <strong>{t('room.facilities')}</strong></h5>
                        <div className="container">
                            <div className="row">
                                {room.facilities.map((facility, index) => (
                                    <div key={index} className="col-md-4">
                                        <p>{facility}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {t('room.close')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default Room;
